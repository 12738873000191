<template>
  <div>
    <appBarMobile
      color="primary"
      app-name="my-nsw-gov"
      :title="barLabel"
      :show-search-filter="true"
      search-filter-type="Essentials"
      :search-label="searchLabel"
    >
      <template slot="appIcon">
        <AppIcon
          app-name="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>
      <template slot="myEssentials">
        <MyEssentials :essentials-data="essentialsData" />
      </template>
    </appBarMobile>
    <TabNavigation @change="setActive" 
      fixedTabs 
      class="ma-n4" 
      :items="[{tab:'My Essentials'},{tab:'My Bookmarks'}]">
    <template slot="content1">
    <div class="ma-4">
    <Essentials
      :local-key="tokens.user_name"
      :filter="filters.Essentials"
      :essentials-data="essentialsData"
      :deep-link="deepLink"
    />
    </div>
    </template>
    <template slot="content2">
    <div class="mt-4">
     <Bookmarks
      :bookmarksObj="bookmarksData"
      :filters="filters.Essentials"
    />
    </div>
    </template>
    </TabNavigation>    
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import  Essentials from "@/components/Essentials";
  import Bookmarks from "@/components/Bookmarks";
  import appBarMobile from "@/components/appBarMobile";
  import { AppIcon, MyEssentials, TabNavigation} from "@nswdoe/doe-ui-core";
  export default {
    components: {
      Essentials,
      appBarMobile,
      AppIcon,
      MyEssentials,
      TabNavigation,
      Bookmarks
    },
    computed: {
      ...mapGetters({
        tokens: 'tokens',
        isLoading: 'isLoading',
        essentialsData: "myEssentialsApps",
        bookmarksData: "myBookmarks",
        filters:"filters"
      }),
      searchLabel(){
        return this.activeTab ? 'Search Essentials' : 'Search Bookmarks'
      },
      barLabel(){
        return this.activeTab ? 'Essentials' : 'Bookmarks'
      }
    },
    created() {
      this.$store.dispatch("fetchMyEssentialsApps");
      this.$store.dispatch("fetchMyBookmarks");     
      if(this.$route.query && this.$route.query.deeplink){
        this.deepLink = this.$route.query.deeplink;
      }
      window.scrollTo(0,0);
    },
    data:function(){
      return {
       activeTab: false,
       deepLink:null
      }
    },
    methods:{
      setActive(){
        this.activeTab = !this.activeTab;
      }
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .theme--light .v-tabs-bar{
  background-color: #041E42 !important;
  color:#fff !important;
}
::v-deep .theme--light .v-tab:not(.v-tab--active){
  color:#fff !important;
}
::v-deep .theme--light .v-tabs-slider{ color:#CE0037 !important}
::v-deep .theme--dark.v-tabs-items{ background:none !important;}
</style>