<template>
  <div class="myEssentials">
    <div 
      class="subheading" 
      role="heading" 
      v-if="frequentData && frequentData.length > 0 && filter==''"
      >
      Frequently used
    </div>
    <v-row class="pt-3" v-if="frequentData && frequentData.length > 0 && filter==''">
      <v-col
        class="text-center"
        v-for="app in frequentData"
        :key="'recent_'+app.id"
        :name="app.name"
        @click="appClick(app)"
      >
        <div>
          <a
            href="#"
          >
            <svg :class="'icon ' + app.iconClass">
              <use v-bind="{ 'xlink:href': app.xlinkHref }" />
            </svg>
            <span
              role="heading"
              class="appTitle"
              aria-level="1"
              aria-hidden="true"
            >{{ app.title ? app.title.length > 18 ? app.title.substring(0,15)+'...': app.title : '' }}</span>
          </a>
        </div>
      </v-col>
    </v-row>
    <div
      class="text-center pt-9 no-result"
      v-if="mappedData && mappedData.length == 0 && this.filter"
    >
      No results found for "{{ this.filter }}"
    </div>
    <div
      :class="frequentData && frequentData.length > 0 ? 'subheading mt-5':'subheading'"
      v-if="mappedData.length > 0 && filter ==''"
      role="heading" >
      All Essentials
    </div>
    <v-row class="pt-3" v-if="mappedData.length > 0">
      <v-col
        class="text-center"
        v-for="app in mappedData"
        :key="app.id"
        :name="app.name"
        xs4
        @click="appClick(app)"
      >
        <div>
          <a
            href="#"
            :title="app.title"
          >
            <svg :class="'icon ' + app.iconClass">
              <use v-bind="{ 'xlink:href': app.xlinkHref }" />
            </svg>
            <span
              role="heading"
              class="appTitle"
              aria-level="1"
              aria-hidden="true"
            >{{ app.title ? app.title.length > 18 ? app.title.substring(0,15)+'...': app.title : '' }}</span>
          </a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import loadash from 'lodash';
import utils from '@/settings/utils';
  export default {
    name: "Essentials",
    props: {
      essentialsData: {
        type: Array,
        default: function(){
          return [];
        }
      },
      localKey:{
        type: String,
        default:'user'
      },
      filter:{
        type: String,
        default: ""
      },
      deepLink:{
        type: String,
        default: ""
      }
    },
    created(){
      if(this.deepLink != null){
        let app = {url: this.deepLink};
        this.openApplication(app);
      }
    },
    computed: {      
      mappedData() {
        let essentialApp = this.essentialsData;
        if(this.filter){
          essentialApp = essentialApp.filter( app=> app.title.toLowerCase().indexOf(this.filter) > -1);
        }
        let essentialData = essentialApp ? essentialApp.map((data) => {
          if (data.icon in this.iconToClass) {
            return {...data, 
              iconClass: this.iconToClass[data.icon],
              icon: data.icon,
              xlinkHref: '#' + data.icon
            };
          }
          return data;
          
        }): [];
        let sortedEssentials = loadash.sortBy(essentialData,[app=>app.title.toLowerCase()]);
        if(sortedEssentials.length > 0){
        return sortedEssentials;
        }else{
          return essentialData; }
      },
      frequentData() {
        let essentialApp = this.sortEssentials(this.frequentlyUsed).filter(essential => this.essentialsData.filter(essnetialApp => essnetialApp.id == essential.id).length > 0);
        if(this.filter){
          essentialApp = essentialApp.filter( app=> app.title.toLowerCase().indexOf(this.filter) > -1);
        }
        return essentialApp.map((data) => {
          if (data.icon in this.iconToClass) {
            return {...data, 
              iconClass: this.iconToClass[data.icon],
              icon: data.icon.substring(3),
              xlinkHref: '#' + data.icon.substring(3)
            };
          }
          return data;
        });
      }
    },
    methods:{
      appClick: function(item){
        item["frequentCounter"] = 1;
        let loggedInUser = this.localKey;
        if(localStorage && utils.getLocalStorage(loggedInUser,"essentials")){
          let frequentDataObj = utils.getLocalStorage(loggedInUser,"essentials");
          let allowStorage = true;
          frequentDataObj.forEach(function(obj,index){
            if(obj.id == item.id){
              allowStorage = false;
              item["frequentCounter"] = parseInt(obj["frequentCounter"]) + 1;
              frequentDataObj[index] = item;
              return;
            }
          });

         if(allowStorage)
           frequentDataObj.push(item);
          utils.setLocalStorage(loggedInUser,'essentials',JSON.stringify(frequentDataObj));
        }else if(localStorage){
          let frequentDataObj = [];
          frequentDataObj[0] = item;
          utils.setLocalStorage(loggedInUser,'essentials',JSON.stringify(frequentDataObj)); 
        }
        let frequentlItems = localStorage && utils.getLocalStorage(loggedInUser,'essentials') ? utils.getLocalStorage(loggedInUser,'essentials') : "[]";
        this.frequentlyUsed = JSON.stringify(frequentlItems);
        
        this.openApplication(item);
      },
      sortEssentials: function(essentialsObj){
        essentialsObj = JSON.parse(essentialsObj);
        let sortedObj = loadash.sortBy(essentialsObj,"frequentCounter");
        if(Array.isArray(sortedObj)) sortedObj.reverse();
        return sortedObj.slice(0,6);
      },
      openApplication: function(item){
        this.$store.dispatch('essentialsCookie',{appName:item.title,appUrl:(item.url ? item.url : item.uri ? item.uri : ''), appFrom:'/essentials'}).then(()=>{
          this.$store.dispatch('setLoader',true);
        });
      }
    },
    filters:{
      essentialFilter: function(essentialApps){
        if(essentialApps){
          return essentialApps.filters(app => app.name.indexOf(this.filters) != -1);
        }
      }
    },
    data() {
      let loggedInUser = this.localKey;
      let storageContent = [];
      if(localStorage)
       storageContent = utils.getLocalStorage(loggedInUser,'essentials') ? JSON.stringify(utils.getLocalStorage(loggedInUser,'essentials')) : "[]"
      return {
        iconToClass: window.iconToClass ? window.iconToClass : {},
        frequentlyUsed: storageContent
      }
    }
  }
</script>
<style type="scss" scoped>
  .my-essentials {
    width: 18.9375rem;
    padding-bottom: 50px;
  }
  .icon {
    width: 3.875rem;
    height: 3.875rem;
    border-radius: 7px;
  }
  .appTitle {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #121212;
  }
  .subheading{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #121212;
  }
  .no-result{
    word-wrap: break-word;
  }
  .col{
    flex-grow: 0;
    flex-basis:33%;
    max-width: 33%;
  }
  .tabletPortrait .col{
    flex-grow: 0;
    flex-basis:19%;
  }
  .tabletLandscape .col{
    flex-grow: 0;
    flex-basis:12%;
    max-width: 12%;
  }
  .v-application a{
    text-decoration: none;
  }

</style>